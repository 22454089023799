import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { QButton } from '../../../Q-Components/QButton';
import { QModal } from '../../../Q-Components/QModal';
import { QModalTitle } from '../../../Q-Components/QModalTitle';
import { QModalContent } from '../../../Q-Components/QModalContent';
import { QModalActions } from '../../../Q-Components/QModalActions';
import { QTypography } from '../../../Q-Components/QTypography';

const SharingAlertTitle = ({ setIsOpen }) => (
  <QModalTitle onClose={() => setIsOpen(false)} closeIcon={<CloseIcon />}>
    <QTypography variant="h3Medium">Sharing Alert</QTypography>
  </QModalTitle>
);

const SharingAlertBody = () => (
  <QModalContent>
    <p>
      This app contains appium script. Do you want to share it to the new user?
    </p>
  </QModalContent>
);

const SharingAlertAction = ({ addAppToUser, setIsOpen }) => {
  const handleConfirm = sharedScript => {
    addAppToUser({ sharedScript });
    setIsOpen(false);
  };

  return (
    <QModalActions>
      <QButton
        color="primary"
        variant="filled"
        onClick={() => handleConfirm(true)}
      >
        Add with sharing
      </QButton>
      <QButton
        color="primary"
        variant="outline"
        onClick={() => handleConfirm(false)}
      >
        Add without sharing
      </QButton>
    </QModalActions>
  );
};

export const SharingAlertModal = ({
  isSharingAlertModalOpen,
  setIsSharingAlertModalOpen,
  addAppToUser,
}) => (
  <QModal
    isOpen={isSharingAlertModalOpen}
    open={isSharingAlertModalOpen}
    toggle={() => setIsSharingAlertModalOpen(!isSharingAlertModalOpen)}
    title={<SharingAlertTitle setIsOpen={setIsSharingAlertModalOpen} />}
    content={<SharingAlertBody />}
    actions={
      <SharingAlertAction
        addAppToUser={addAppToUser}
        setIsOpen={setIsSharingAlertModalOpen}
      />
    }
  >
    <SharingAlertTitle setIsOpen={setIsSharingAlertModalOpen} />
    <SharingAlertBody />
    <SharingAlertAction
      addAppToUser={addAppToUser}
      setIsOpen={setIsSharingAlertModalOpen}
    />
  </QModal>
);
