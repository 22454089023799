import React from 'react';
import { useSelector } from 'react-redux';

import DataTable from '../../DataTable/DataTable';
import i18n from '../../../localization/i18n';

export default function SbomVulnerabilitiesDataTable({ evidence = [] }) {
  const { sbom: sbomAccess } = useSelector(({ emmApp }) => emmApp.userAccess);

  if (!sbomAccess) {
    return (
      <div>
        <p>
          Your user currently does not have permission to view SBOM analysis
          details. Contact your Administrator or{' '}
          <a href="mailto:sales@quokka.io">sales@quokka.io</a> to view the full
          SBOM results and details on the CVEs detected.
        </p>
      </div>
    );
  }

  return (
    <div>
      {evidence.length === 0 ? (
        <span>{i18n.t('No vulnerabilities were found')}</span>
      ) : (
        <DataTable
          filterable={false}
          data={evidence}
          columns={[
            {
              label: i18n.t('ID'),
              keyName: 'id',
              filterable: true,
            },
            {
              label: i18n.t('Description'),
              keyName: 'description',
              width: '40%',
            },
            {
              label: i18n.t('Source'),
              keyName: 'source',
              component: ({ value }) => (
                <a href={value.url}>{value.name || value.url}</a>
              ),
            },
            {
              label: i18n.t('Score'),
              keyName: 'ratings',
              component: ({ value }) => <>{value[0]?.score}</>,
            },
            {
              label: i18n.t('Severity'),
              keyName: 'ratings',
              component: ({ value }) => <>{value[0]?.severity}</>,
            },
            {
              label: i18n.t('Method'),
              keyName: 'ratings',
              component: ({ value }) => <>{value[0]?.method}</>,
            },
            {
              label: i18n.t('Vector'),
              keyName: 'ratings',
              component: ({ value }) => <>{value[0]?.vector}</>,
            },
          ]}
        />
      )}
    </div>
  );
}
